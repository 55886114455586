import React from 'react'

import Page from '../../components/page'
import withRoot from '../../withRoot'

import workspace from '../../static/editor/workspace.png'
import newface from '../../static/editor/newface.png'
import newfacediag from '../../static/editor/newfacediag.png'
import fulleditor from '../../static/editor/full.png'
import stack from '../../static/editor/stack.png'
import addelements from '../../static/editor/addelements.png'
import properties from '../../static/editor/properties.png'

import { withStyles } from '@material-ui/core/styles'

import {
  Typography,
} from '@material-ui/core'

const styles = theme => ({
  section: {
    marginTop: theme.spacing(4),
    color: theme.palette.secondary.main,
  },
  parag: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  img: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
    borderRadius: '5px',
    maxWidth: '100%',
  },
})

@withRoot
@withStyles(styles)
export default class Editor extends React.Component {
  render() {
    const { classes } = this.props

    return (
      <Page toggleTheme={this.props.toggleTheme} title="Welcome to the FitFace editor">
        <Typography>In a matter of minutes, you will be able to make your own designs without a line of code.</Typography>

        <Typography variant="h5" className={classes.section}>New face</Typography>
        <Typography className={classes.parag}>
          Go to your workspace by clicking on the top right user menu.
        </Typography>
        <img src={workspace} className={classes.img} />
        <Typography className={classes.parag}>
          You want to create a new design, just click on the <b>Create a New Clockface</b> button.
        </Typography>
        <img src={newface} className={classes.img} />
        <Typography className={classes.parag}>
          A dialog will appear. Choose a name for your design, the model of the watch and optionally a template to start with. And press <b>Create</b>.
        </Typography>
        <img src={newfacediag} className={classes.img} />
        <Typography className={classes.parag}>
          This is what you see when you edit a clockface.
        </Typography>
        <img src={fulleditor} className={classes.img} />

        <Typography variant="h5" className={classes.section}>The Stack</Typography>
        <Typography className={classes.parag}>
          On the left side of the editor, you can see all the elements of your clockface in the Stack.
        </Typography>
        <img src={stack} className={classes.img} />
        <Typography className={classes.parag}>
          Just drag and drop your elements in the stack to reorder them.<br />
          The stack also display a <b>Background</b> element which permits to change the background color of your design.
        </Typography>

        <Typography variant="h5" className={classes.section}>Adding Elements</Typography>
        <Typography className={classes.parag}>
          You can add new elements with the <b>+</b> button on the bottom right.
        </Typography>
        <img src={addelements} className={classes.img} />
        <Typography className={classes.parag}>
          Clicking on the button will display the Elements Panel.<br />
          Just select a shape and a default element will be added in your design.
        </Typography>

        <Typography variant="h5" className={classes.section}>Properties Panel</Typography>
        <Typography className={classes.parag}>
          When an element of your design is selected, the Properties Panel is automatically displayed on the right.<br />
          It permits to set all the properties of the element.
        </Typography>
        <img src={properties} className={classes.img} />

        <Typography variant="h5" className={classes.section}>Time Machine</Typography>
        <Typography className={classes.parag}>
          At the bottom, the time machine allows you to control the time displayed on your design and see your face in action.
        </Typography>
      </Page>
    )
  }
}
